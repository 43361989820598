.knot-detail-view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

  .tab-component-wrapper {
    width: 90%;
    margin-bottom: 20px;
  }

  .knot-detail-scorecard-wrapper {
    .knot-detail-scorecard-headline {
      padding-left: 16px;
      font-weight: bold;
      font-size: 1.2em;
    }
    .MuiTableCell-sizeSmall {
      padding: 0px 24px 0px 16px;
    }
  }

  .knot-detail-image-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin-top: -25px;

    img {
      max-width: 80%;
    }
  }

  .station-wrapper-overview {
    width: 80%;
    max-width: 600px;

    .input-component-wrapper {
      margin: 10px 0;
    }

    .knot-title {
      font-weight: bold;
    }

    .station-wrapper {
      .station-wrapper-add-button {
        max-width: 65px;
        cursor: pointer;
      }
    }
  }

  .table-component-wrapper {
    .MuiTableRow-root:hover {
      background-color: transparent !important;
    }

    .table-image-column {
      width: 100%;
      text-align: center;

      img {
        height: 25px;
        width: 25px;
      }
    }

    .table-component-table-row-cell {
      cursor: unset;
    }
  }
}
