:root {
  font-size: 0.9rem;
}

.headline-separator {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  height: min-content;
  margin: 25px 5px;
  padding: 10px 0;
  border-bottom: 1px solid #d3d3d3;

  &.station-edit-headline {
    margin-bottom: 0;
    border: none;
  }

  .breadcrumb-headline-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: min-content;

    .breadcrumb-headline-arrow {
      margin: 0 5px;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid #ffc000;
    }
  }
}

.layout-component-wrapper .layout-component-top-menu {
  min-height: 60px !important;
}
.full-height-wrapper {
  height: 100%;
}
