#powerwall-wrapper {
  width: 90%;
  margin: 0 auto;

  #powerwall-linie-monitor-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    flex-wrap: wrap;

    &.is-mobile {
      justify-content: center;
    }

    #powerwall-linie-monitor-reifegrad {
      width: 70%;

      &.is-mobile {
        width: 100%;
      }

      min-width: 250px;
      margin: 0 2%;
      .chart-component-wrapper {
        width: 100%;
      }
    }
    #powerwall-linie-monitor-performance {
      width: 20%;
      margin: 0 2%;

      &.is-mobile {
        width: 90%;
        margin-top: 25px;
      }

      .chart-component-wrapper {
        width: 100%;
      }
    }
  }
  #powerwall-linie-monitor-stationeg {
    margin: 25px 2%;
  }

  #powerwall-loader-wrapper {
    height: 160px;
  }

  p {
    text-align: center;
  }
}
