.layout-component-content-left-menu-content > div {
  height: 100%;
}

.sidemenu-knot-tree-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tree-component-admin-context-menu-icon-wrapper {
    * svg {
      height: 15px;
      width: 15px;
      margin-right: 5px;

      &.delete-icon {
        path {
          fill: darkred;
        }
      }
    }
  }

  .sidemenu-knot-tree-tree {
    flex-grow: 1;
    overflow-y: auto;
  }

  .sidemenu-knot-tree-add {
    text-align: center;

    .input-component-wrapper {
      margin: 5px 0;
    }

    svg {
      margin: 0 auto;
      width: 40px;
      cursor: pointer;

      path {
        fill: #337ab7 !important;
      }
    }
  }
}

.tree-component-admin-context-menu-icon-wrapper {
  > div {
    svg {
      color: #b5b4b4;
    }
  }
}

.tree-component-wrapper.mobile {
  margin-bottom: 150px;
}
